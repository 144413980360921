.app {
    text-align: center;
    margin: 0;
}

.content {
    text-align: center;
    background: #FFFFFF;
    margin: 0;
    padding: 5px;
}

.menu {
    background: #EAEAEA;
    display: block;
    max-width: 785px;
    margin: auto;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;
        // letter-spacing: 0.175em;
    }

    li {
        float: left;
    }

    li button {
        display: block;
        color: black;
        padding: 10px 18px 8px 18px;
        border-radius: 4px 4px 0px 0px;
        border: none;
        font: inherit;
        cursor: pointer;
    }

    li a {
        display: block;
        color: black;
        text-decoration: none;
        padding: 10px 18px 8px 18px;
        border-radius: 4px 4px 0px 0px;
    }

    li button:hover:not(.active) {
        background: #FFFFFF;
    }

    li a:hover:not(.active) {
        background: #FFFFFF;
    }

    .active {
        background: #FFFFFF;
    }
}

.priceFont {
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    @media screen and (max-height: 740px) {
        font-size: 13px;
        line-height: 13px;
    }
}

.bottomMenu {
    background: #EAEAEA;
    padding-bottom: 25px;
}